import React from "react";
import { graphql } from "gatsby";
import PageWithCmsWrapper from "../../../components/shared/page-with-cms-wrapper";

const EmergencyServices = ({
                       path,
                       data: {
                           strapiSolutionsIndustriesEmergencyServices: {
                             Title,
                             Content,
                             SeoTitle,
                             MetaDescriptions,
                           },
                       },
                   }) => {
    return (
        <PageWithCmsWrapper
            path={path}
            title={Title}
            content={Content}
            seoProps={{ title: SeoTitle, description: MetaDescriptions}}
        />
    );
};


export default EmergencyServices;

export const query = graphql`
  query SolutionsIndustriesEmergencyServicesQuery {
    strapiSolutionsIndustriesEmergencyServices {
      Title
      Content
      SeoTitle
      MetaDescriptions
    }
  }
`;
